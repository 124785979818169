@font-face {
    font-family: "Bold";
    src: local("Bold"), url(../fonts/GTWalsheimPro-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Medium";
    src: local("Medium"), url(../fonts/GTWalsheimPro-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Regular";
    src: local("Regular"), url(../fonts/GTWalsheimPro-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Light";
    src: local("Light"), url(../fonts/GTWalsheimPro-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Thin";
    src: local("Thin"), url(../fonts/GTWalsheimPro-Thin.ttf) format("truetype");
}